import { AxiosResponse } from 'axios'

export const downloadBinaryFile = (
  data: ArrayBuffer,
  headers: AxiosResponse['headers']
): void => {
  try {
    const blob = new Blob([data], { type: headers['content-type'] })
    const contentDisposition = headers['content-disposition']
    let fileName = 'downloaded_file.xlsx'

    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename=([^;]+)/)

      if (fileNameMatch?.[1]) {
        fileName = fileNameMatch[1]
      }
    }

    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')

    try {
      a.href = url

      a.download = fileName

      document.body.appendChild(a)

      a.click()
    } finally {
      document.body.removeChild(a)

      URL.revokeObjectURL(url)
    }
  } catch (error) {
    console.error('Ошибка при скачивании файла:', error)

    throw new Error('Не удалось скачать файл')
  }
}
